import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";
import useWindowSize from "../helpers/useWindowSize";

export default function SimpleFunSlider({ styles }) {
  const [sldrProps, setSldrProps] = React.useState({
    spaceBetween: 40,
    slidesPerView: 1,
    pagination: true,
    loop: true,
  });
  const [intialize, setInitialize] = React.useState(false);
  const size = useWindowSize();
  React.useEffect(() => {
    if (size?.width) {
      setInitialize(false);
      size?.width < 1024 &&
        setSldrProps({
          spaceBetween: 40,
          slidesPerView: 1,
          pagination: true,
          loop: false,
          autoplay: { delay: 3500 },
          allowTouchMove: true,
        });
      size?.width >= 1024 &&
        setSldrProps({
          spaceBetween: 40,
          slidesPerView: 3,
          pagination: false,

          loop: false,
          autoplay: false,
          allowTouchMove: false,
        });
    }
    setInitialize(true);
    //console.log(size?.width, intialize, sldrProps);
  }, [size]);

  return (
    <div className="w-full h-full  relative">
      {/* <div id="swpr-prev" className="absolute left-8 top-2/3 -translate-y-1/2 z-10">
        <Btn color="slideNav">
          <img className="" src="/assets/hero-screen/arrow.svg" alt="Prev Slide" />
        </Btn>
      </div>
      <div id="swpr-next" className="absolute right-8 top-2/3 -translate-y-1/2 z-10">
        <Btn color="slideNav">
          <img className="rotate-180" src="/assets/hero-screen/arrow.svg" alt="Next Slide" />
        </Btn>
      </div> */}
      {intialize && (
        <Swiper
          modules={[Navigation, Autoplay, Pagination]}
          autoplay={{ delay: 3500 }}
          className="simple-fun-slider"
          centeredSlides={false}
          {...sldrProps}
          // navigation={{
          //   nextEl: "#swpr-next",
          //   prevEl: "#swpr-prev",
          // }}
        >
          <SwiperSlide key={1}>
            <div className="w-full">
              <img className="w-full " src="/assets/simple-fun/slide1.png" />

              <p className={styles.slideHeading}>Choose a number</p>
              <p className={styles.slideDescr}>
                Attack the opposing player by choosing a random number (0-10). The defender will then try to defend by guessing or at least close to your number. The further away their guess is fro your chosen number - the more damage you will inflict!
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide key={2}>
            <div className="w-full">
              <img className=" w-full" src="/assets/simple-fun/slide2.png" />

              <p className={styles.slideHeading}>USE ITEMS</p>
              <p className={styles.slideDescr}>
                There are three items that you can use in the game - but you can only use them once per round. Think carefully in how you can form a good strategy to win. There are two defending items and one attacking item. What will be your strategy?
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide key={3}>
            <div className="w-full">
              <img className="w-full " src="/assets/simple-fun/slide3.png" />

              <p className={styles.slideHeading}>WIN/LOSE ROUND</p>
              <p className={styles.slideDescr}>
                Take turns until one of the players lose all health and then win the battle! There is a lot at stake - since the winner will progress to the next round to potentially become a champion - and the loser’s NFT will turn into a pile of charcoal ash!
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      )}
    </div>
  );
}
