export const players = [
  { img: "/assets/game-descr/archer1.png", burnt: false },
  { img: "/assets/game-descr/archer2.png", burnt: false },
  { img: "/assets/will-you/loser.png", burnt: true },
  { img: "/assets/game-descr/archer2.png", burnt: false },
  { img: "/assets/game-descr/archer4.png", burnt: false },
  { img: "/assets/game-descr/archer5.png", burnt: false },
  { img: "/assets/game-descr/thrower1.png", burnt: false },
  { img: "/assets/game-descr/axeman2.png", burnt: false },
  { img: "/assets/will-you/loser.png", burnt: true },
  { img: "/assets/game-descr/axeman2.png", burnt: false },
  { img: "/assets/game-descr/axeman1.png", burnt: false },
  { img: "/assets/will-you/loser.png", burnt: true },
  { img: "/assets/game-descr/axeman4.png", burnt: false },
  { img: "/assets/game-descr/archer2.png", burnt: false },
  { img: "/assets/game-descr/archer5.png", burnt: false },
  { img: "/assets/will-you/loser.png", burnt: true },
  { img: "/assets/game-descr/archer2.png", burnt: false },
  { img: "/assets/game-descr/archer4.png", burnt: false },
  { img: "/assets/game-descr/archer3.png", burnt: false },
  { img: "/assets/game-descr/thrower5.png", burnt: false },
  { img: "/assets/game-descr/axeman1.png", burnt: false },
  { img: "/assets/will-you/loser.png", burnt: true },
  { img: "/assets/game-descr/axeman1.png", burnt: false },
  { img: "/assets/game-descr/axeman3.png", burnt: false },
  { img: "/assets/will-you/loser.png", burnt: true },
  { img: "/assets/game-descr/axeman4.png", burnt: false },
  { img: "/assets/game-descr/axeman2.png", burnt: false },
  { img: "/assets/game-descr/axeman1.png", burnt: false },
  { img: "/assets/game-descr/boulder2.png", burnt: false },
  { img: "/assets/game-descr/boulder4.png", burnt: false },
  { img: "/assets/game-descr/boulder.png", burnt: false },
  { img: "/assets/will-you/loser.png", burnt: true },
  { img: "/assets/game-descr/boulder6.png", burnt: false },
  { img: "/assets/game-descr/boulder1.png", burnt: false },
  { img: "/assets/will-you/loser.png", burnt: true },
  { img: "/assets/game-descr/thrower2.png", burnt: false },
  { img: "/assets/game-descr/thrower1.png", burnt: false },
  { img: "/assets/game-descr/thrower2.png", burnt: false },
  { img: "/assets/game-descr/axeman4.png", burnt: false },
  { img: "/assets/game-descr/axeman2.png", burnt: false },
  { img: "/assets/game-descr/boulder3.png", burnt: false },
  { img: "/assets/game-descr/boulder.png", burnt: false },
  { img: "/assets/game-descr/boulder1.png", burnt: false },
  { img: "/assets/will-you/loser.png", burnt: true },
  { img: "/assets/game-descr/boulder4.png", burnt: false },
  { img: "/assets/game-descr/boulder2.png", burnt: false },
  { img: "/assets/will-you/loser.png", burnt: true },
  { img: "/assets/game-descr/thrower2.png", burnt: false },
  { img: "/assets/game-descr/thrower3.png", burnt: false },
  { img: "/assets/game-descr/thrower4.png", burnt: false },
];
