import React, {useEffect, useState} from "react";

const ProgressBar = (props) => {
  const bgcolor = "#C67C2B";
  const [progress, setProgress] = useState(10);

  const containerStyles = {
    height: 20,
    width: '100%',
    backgroundColor: "#593b0f",
    borderRadius: 50,
    margin: 5
  }

  const fillerStyles = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: 'width 1s ease-in-out',
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
    fontSize: 11,
    lineHeight: 0.5,
  }

  useEffect(() => {
    let timer_loader = setInterval(() => {
      let _val = progress + 10;
      if( _val > 99 ) { _val = 0; }
      setProgress( _val);
    },3000);
  });



  return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
         {/* <span style={labelStyles}>{`${completed}%`}</span>*/}
        </div>
      </div>
  );
};

export default ProgressBar;