import React from "react";
import styles from "../../styles/simple-fun.module.scss";
import SimpleFunSlider from "./SimpleFunSlider";
export default function SimpleFun() {
  return (
    <div className={styles.root} id="simple_fun">
      <div className="wrap">
        <p className="heading text-center mb-5">
          SIMPLE YET <br className="lg:hidden" /> FUN GAME PLAY
        </p>
        <div className="text-justify text-sm py-5">
          The Burn Battle game is very simple, yet fun game. We purposely did not want a complex game that would give users who have more skill an upper hand, but we also needed to think of something that was based around a bit of guessing/luck - but also some slight strategy to it so that everybody has a chance to progress in the tournament to win the grand prize. We also did not want to give any specific characters any extra upper hand either - so you can be sure that you have just as much of a chance to win the tournament as somebody who has played for many reasons.
        </div>
        <div className="overflow-hidden relative w-full">
          <SimpleFunSlider styles={styles} />
        </div>
      </div>
    </div>
  );
}
