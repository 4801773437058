import React from "react";
import styles from "../../styles/char.module.scss";
import Btn from "../Btn/Btn";
import Swal from "sweetalert2";
export default function Char({ char }) {

  /**
   * Load all NFT assets of this user
   */
  function open_character( char ) {
    //console.log( char );
    let url = char?.status == 1 ? char?.preview : '/assets/burnt.png';
    //console.log( url );
    Swal.fire({
      title: "Warrior #" + Number(char?.token_index + 1),
      text: '',
      html: '<span class="w-full block">'+ char?.specie_label +'</span><br/><img src="'+ url +'" id="swal_modal_warrior"> ',
      //iconHtml: '<img src="'+ url +'" id="swal_modal_warrior"> ',
      customClass: {
        icon: 'no-border',
        title: 'text-bolder text-uppercase',
        text: 'text-white'
      },
      confirmButtonText: 'OK'
    });
  }

  return (
    <div className={styles.card}>
      <div className={styles.inner} style={{"position" : "relative"}}>
        <img className="w-full transition-all hover:scale-110" src={ char?.status == 1 ? char?.preview : '/assets/burnt.png'}
             style={ char?.status == 1 ? {"paddingTop" : "0px"} : {"paddingTop" : "20px"}}/>

        <p className={styles.name} style={{"fontSize": "10pt"}}>{char?.specie_label}</p>
        <div className="w-full text-center">
          { char?.popup == 'yes' ? (
            <div>
              { char?.status == 1 ? (
                  <div className='badge badge_green'> Alive </div>
              ) : (
                  <div className='badge badge_red'> Burnt </div>
              )}
              <div className="mini_text pt-2">ID: #{char?.token_index}</div>
            </div>
          ) : (
            <Btn width="w-full" color={"bronzePrice"} onClick={()=>{ open_character(char) }}>
              <span className="uppercase relative top-0.5 ">Details</span>
            </Btn>
          )}
        </div>
      </div>
    </div>
  );
}
