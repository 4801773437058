import Swal from "sweetalert2";

export default () => {

   /** ==============================================================
   * ================== Preloader
   * ============================================================== */
  function show_Preloader() {
    //$q.loading.show()

    // hiding in 30s
    let timer = setTimeout(() => {
      //$q.loading.hide()
      timer = void 0
    }, 30 * 1000)
  }

  function hide_Preloader() {
    //$q.loading.hide();
  }


  /** ==============================================================
   * Show info message
   * @param {string} text
   * @param {(success|error)} type */

  function infoMessage(text, type) {
    let color = '';
    if (type !== 'error') {
      type = 'success';  color = 'primary';
    } else {
      color = 'red';
    }
   /* $q.notify({
      message: text,
      icon: 'notifications_none',
      color: color,
      position: 'top',
      actions: [
        {
          label: 'OK', color: 'white', handler: () => {
          }
        }
      ]
    })*/
  }

  /** ==============================================================
   * Show info message
   * @param {string} title
   * @param {string} message
   * @param {(success|error|soon)} icon */

  function modalAlert(title, message, icon ) {
    // icon: 'success', 'error', 'coming_soon'
    if( !icon || icon == ''){ icon = 'success'; }
    Swal.fire({
      title: title,
      text: message,
      iconHtml: '<img src="./assets/modal/modal_'+ icon +'.svg" id="swal_modal_icon">',
      customClass: {
        icon: 'no-border',
        title: 'text-bolder text-uppercase',
        text: 'text-white'
      },
      confirmButtonText: 'OK'
    });
  }


  /**
   * transform_date from nanoseconds to date Y-m-d H:i:s
   */
  const transform_date_nano = ( time_nano  ) => {
    let time_nano_int = parseInt(time_nano);
    // convert to unix timestamp
    let time_unix = time_nano_int / 1000000;
    let time_date = new Date( time_unix );
    // time_date to Y-m-d H:i:s
    let time_str = time_date.toISOString().slice(0, 19).replace('T', ' ');
    return time_str;
  }


  /** ==============================================================
   * ================== transform date
   *  @param {string} old_date
   *  @param {(numbers|full24time|24time|12time|full12time|mini|date)} flag
   * ============================================================== */
  function transform_date(old_date, flag) {
    let result;
    if (old_date) { //old_date.replace("T", " ");
      let mdate = old_date.replace("T", " ").split(" ");

      let time_part;
      let date_part = mdate[0];
      let time;
      if (mdate[1]) {
        time = mdate[1].split(":");
        time_part = time[0] + ":" + time[1];
      } else {  time_part = '';  }

      let date_array = date_part.split('-');

      let year = date_array[0];
      let month_index = +date_array[1] * 1;
      let month = date_array[1];
      let day = date_array[2];

      if (flag == 'numbers') {
        result = day + "/" + month + "/" + year + " " + time_part;
      }
      if (flag == 'full24time') {
        result = year + "-" + month + "-" + day + " " + time_part;
      }
      if (flag == '24time') {
        result = time_part;
      }
      if (flag == '12time') {
        var H = +time_part.substr(0, 2);
        var h = H % 12 || 12;
        var ampm = (H < 12 || H === 24) ? "AM" : "PM";
        result = h + time_part.substr(2, 3) + ' ' + ampm;
      }
      if (flag == 'full12time') {
        var H = +time_part.substr(0, 2);
        var h = H % 12 || 12;
        var ampm = (H < 12 || H === 24) ? "AM" : "PM";
        result = day + "/" + month + "/" + year + " " + h + time_part.substr(2, 3) + ' ' + ampm;
      }
      if (flag == 'date' || flag == 'mini') {
        result = time_part;
        result = day + "/" + month + "/" + year;
      }
    }
    //console.log( '>>>'+ result )
    return result;
  }

  /** ==============================================================
   * ================== get_random_color ============================
   * ============================================================== */
  function get_random_color() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  /** ==============================================================
   * ================== get_today_date ============================
   * ============================================================== */
  function get_today_date() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  }

  /** ==============================================================
   * ================== get_date_now_timezone ======================
   * ============================================================== */
  function get_date_now_timezone() {
    let created = new Date();
    let month = created.getMonth() + 1; if( month <= 9 ){ month = '0' + month; }
    let day = created.getDate(); if( day <= 9 ){ day = '0' + day; }
    let hours = created.getHours(); if( hours <= 9 ){ hours = '0' + hours; }
    let minutes = created.getMinutes(); if( minutes <= 9 ){ minutes = '0' + minutes; }
    let date = created.getFullYear() + '-' + month + '-' + day;
    let time = hours + ':' + minutes + ':00';
    let dateTime = date + ' ' + time;
    return dateTime;
  }

  /**
   * Add new history row
   */
  function add_to_activity_log( comment ) {
    let dateTime = get_date_now_timezone();
    let params = { comment: comment, wallet: window.client_ledger, created: dateTime  };
    api_query('user-activity-add', 'POST', params, (data) => {
      //console.dir( data);
    }); // end - api query
  }


  /** ==============================================================
   * =================== set_random_background =====================
   * ============================================================== */
  function set_random_background() {
    document.dispatchEvent( new CustomEvent('random_background', { detail: true }));
  }


  /**
   * check_logged
   */
  function check_logged() {
    if ( window.logged_user != true || !window.logged_user ) {   logout(); }
  }

  /**
   * coming_soon
   */
  function coming_soon() {
    modalAlert('Coming Soon', 'We are working hard to implement this functionality', 'soon');
  }

  /** ==============================================================
   * ================== Send data via API ==========================
   * ============================================================== */
  function api_query(url, method, filter, callback) {

    if( method != 'POST') {  method = 'GET';  }

    //............ API REQUEST .............
    //show_Preloader();
    let requestOptions = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("user_token")}`,
      }
    };
    if( method == 'POST' ) {
      requestOptions.body = JSON.stringify(filter);
    }
    fetch(window.api_url + 'api/' + url, requestOptions)
      .then(async response => {
        const data = await response.json();
        hide_Preloader();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        } else {
          return callback(data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        infoMessage(
          'Error: An error occurred while retrieving data from the server', 'error'
        );
      });
    // ....... END - API Request
  }

  /**
   * parse tokens price (add commas to price)
   */
  function parse_tokens_price( amount ){
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  /** ==============================================================
   * ==============  Convert Int from Canister =====================
   * ============================================================== */
  function convert_int( data ) {
    let amount = data + "";
    amount = amount.replace(/n/g, '');
    amount = parseInt( amount );
    return amount;
  }

  /** ==============================================================
   * ===================== get_species_list  =====================
   * ============================================================== */
  function get_species_list() {

    const species = [
      {label: "Any Specie", value: 'all',  gen: "all"},
      {label: 'Achelousaurus', value: 'Achelousaurus', gen: "gen1" },
      {label: 'Allosaurus', value: 'Allosaurus', gen: "gen1"},
    ];
    return species;
  }

  /** ==============================================================
   * ===================== get_rarity_list  =====================
   * ============================================================== */
  function get_rarity_list() {
    let rarities = [
      {label: "All", value: "all" },
      {label: "Common", value: "Common", },
    ]
    return rarities;
  }

  /** ==============================================================
   * ================== parse_nft_image  ============================
   * ============================================================== */
  function parse_nft_image( item ) {
    let link = 'https://'+ window.canisters['burn_battle'] +'.raw.ic0.app/?type=thumbnail&index=' + item.token_index ;
    if( item.demo == 1 ) {
      link = './types/' + item.specie + '.svg';
    } else {
      if( item.status == 2) { link = './images/burnt.png';  }
    }
    return link;
  }

  /**
   * Copy address to Clipboard
   */
  function copyToClipboard(client_ledger) {
    navigator.clipboard.writeText(client_ledger).then(() => {
      infoMessage('The wallet address has been copied to the clipboard', 'success');
    });
  }




  /** ==============================================================
   * ================== Log Out
   * ============================================================== */
  function logout() {
    window.logged_user = false;
    localStorage.setItem("user_token", "");
    //router.push('/login');
  }

  /**
   * visits_counter_add
   */
  function visits_counter_add( page ) {
    api_query('visits-add', 'POST', { page: page }, (data) => {
    }); // end - api query
  }

  return {
    show_Preloader, hide_Preloader, transform_date, get_today_date, transform_date_nano,
    infoMessage, api_query, modalAlert, logout, set_random_background, check_logged, get_date_now_timezone,
    coming_soon, add_to_activity_log, parse_tokens_price, convert_int, visits_counter_add,
    get_species_list, get_rarity_list, parse_nft_image, copyToClipboard
  }
}
