import React, {useEffect, useState} from "react";
import Char from "./Char";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";
import Btn from "../Btn/Btn";

export default function Characters() {

  let [characters, setCharacters] = useState( window.user_tokens );
  let [slides, setSlides]  = useState( [] );

    document.addEventListener('update_slides', function({ detail }) {
        //console.log('user_tokens') ;
        if( window.user_tokens.length > 0 ) {
            setCharacters( window.user_tokens );
            setSlides( window.home_slides );
        }
    });

  /*  document.addEventListener('total_alive', function({ detail }) {
        console.log( "total_alive!!" )
        setCharacters( window.total_alive );
        setSlides( [1,2,3] );
    });*/

  return (
    <div className="relative ">
      <div
        id="descr-prev"
        className="absolute left-0 translate-x-[-20px] lg:-translate-x-[calc(100%+10px)] top-1/2 -translate-y-1/2 z-10"
      >
        <Btn color="">
          <img className="" src="/assets/hero-screen/arrow.svg" alt="Prev Slide" />
        </Btn>
      </div>
      <div
        id="descr-next"
        className="absolute right-0 translate-x-[20px] lg:translate-x-[calc(100%+10px)] top-1/2 -translate-y-1/2 z-10"
      >
        <Btn color="  ">
          <img className="rotate-180" src="/assets/hero-screen/arrow.svg" alt="Next Slide" />
        </Btn>
      </div>
      <Swiper
        modules={[Navigation, Autoplay, Pagination]}
        className="swiper"
        loop={true}
        centeredSlides
        spaceBetween={140}
        slidesPerView={1}
        navigation={{
          nextEl: "#descr-next",
          prevEl: "#descr-prev",
        }}
      >
       {slides.map((slide, k) => (
        <SwiperSlide key={`${Math.random()}_` + k}>
          <div className="grid grid-cols-2 lg:grid-cols-5 lg:gap-6 gap-4">
            {characters.slice(0, 10).map((char, i) => (
              <Char key={`${char.specie_label}_${i}`} char={char} />
            ))}
          </div>
        </SwiperSlide>
       ))}
      </Swiper>
    </div>
  );
}
