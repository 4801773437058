import React, {useState, useRef, useEffect} from "react";
import Btn from "../Btn/Btn";
import { useOutsideClick } from "../helpers/hooks";
import DropdownLayout from "../DropdownLayouts/DropdownLayout";
import ConnectWalletDropdown from "../DropdownLayouts/ConnectWalletDropdown";
import NetworkDropdown from "../DropdownLayouts/NetworkDropdown";
import globals_functions from "../../globals";

//-------- Aptos modules -------
import { AptosClient, AptosAccount, FaucetClient, TokenClient, CoinClient, HexString, Types } from 'aptos';

//-------- Dfinity modules -------
import {Actor, HttpAgent} from "@dfinity/agent";
import {Principal} from "@dfinity/principal";
import {StoicIdentity} from "ic-stoic-identity";


function ConnectWallet({
  showPopup,
  setShowPopup,
  setUserAddress,
  header = false,
  big,
  userAddress,
}) {

  const globals = globals_functions();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownNetwork, setShowDropdownNetwork] = useState(false);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // const logged
  const [logged, setLogged] = useState(false);
  const [wallet, setWallet] = useState(null);


  /** ==============================================================
   * ================ Wallet Connect: Petra  ========================
   * ============================================================== */
  const requestPetraConnect = async () => {

    window.wallet_type = 'petra';
    document.dispatchEvent(new CustomEvent('burn_preloader', {detail: { state: true, message: 'Please wait...' }}));

    let wallet = getPetraWalletInstalled();
    try {
      let response = await wallet.connect();
      if( response ) {
        let account = await wallet.account();
        let wallet_address = account.address;
        window.client_ledger = wallet_address;
        window.client_pub_key = account.publicKey;
        //console.dir( account );
        handleLogin();
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Check Petra Wallet availability
   */
  function getPetraWalletInstalled() {
    if ('aptos' in window) {
      return window.aptos;
    } else {
      globals.infoMessage('Please install Petra Wallet', 'error');
      console.log('Please install Petra Wallet');
    }
  }

  /**
   * Check Martian Wallet availability
   */
  function getMartianWalletInstalled() {

    if ("martian" in window) {
      return(window.martian);
    } else {
      globals.infoMessage('Please install Martian Wallet', 'error');
      //window.open("https://www.martianwallet.xyz/", "_blank");
    }
  }

  /** ==============================================================
   * ================ Wallet Connect: Martian  =====================
   * ============================================================== */
  const requestMartianConnect = async () => {
    // detect if browser safary
    if ( isSafari.value ) {
      globals.modalAlert(
          'Plugin not installed!',
          'Sorry but Martian wallet plugin does not work in this browser. Please open Chrome or Firefox',
          'error'
      );
    } else {
      window.wallet_type = 'martian';
      document.dispatchEvent(new CustomEvent('burn_preloader', {detail: { state: true, message: 'Please wait...' }}));

      let wallet = getMartianWalletInstalled();
      try {
        let response = await window.martian.connect();
        if( response ) {
          let account = response;
          let wallet_address = account.address;
          window.client_ledger = wallet_address;
          window.client_pub_key = account.publicKey;
          //console.dir( account );
          handleLogin();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  /**
   * Buy and mint a new warrior
   */
  function change_network() {
    globals.modalAlert('Coming soon', 'We are working on developing this functionality', 'soon');
  }

  /** ==============================================================
   * ================== send_Login ============================
   * ============================================================== */
  const handleLogin = () => {

    //document.dispatchEvent( new CustomEvent('preloader', { detail: { state: false } }));
    setShowDropdown(false);
    document.dispatchEvent( new CustomEvent('refresh_tokens', { detail: true }));
    window.logged_user = true;
    setLogged(true);
    document.dispatchEvent(  new CustomEvent('show_modal_loader',{
      detail: { text: "", state: false }
    }));

    let params = {
      client_ledger: window.client_ledger,
      wallet_type: window.wallet_type,
      client_pub_key: window.client_pub_key,
    };
    //console.log('handleLogin', params);
    localStorage.setItem('ic_params', JSON.stringify(params));

  };

  /** ==============================================================
   * =================== selectNetwork ICP  ========================
   * ============================================================== */
  const selectNetwork_ICP = async ( emit ) => {
    change_network();
    setShowDropdownNetwork(false);
  };

  const selectNetwork_SOL = async ( emit ) => {
    change_network();
    setShowDropdownNetwork(false);
    //console.log('selectNetwork_SOL');
  };

  const selectNetwork_ETH = async ( emit ) => {
    change_network();
    setShowDropdownNetwork(false);
    //console.log('selectNetwork_ETH');
  }

  const selectNetwork_APT = async ( emit ) => {
    setShowDropdownNetwork(false);
  }

  const selectNetwork_SUI = async ( emit ) => {
    change_network();
    setShowDropdownNetwork(false);
  }

  const selectNetwork_BTC = async ( emit ) => {
    change_network();
    setShowDropdownNetwork(false);
  }


  /** ==============================================================
   * ================== logout & disconnect  ==================== */
  async function disconnect() {

    window.logged_user = false;
    window.client_ledger = '';
    setLogged(false);
    setWallet( '');
    localStorage.removeItem('ic_params');

    if( window.wallet_type = 'martian') {
      await window.martian.disconnect();
      window.location.reload();
    }
    if( window.wallet_type = 'petra') {
      await window.aptos.disconnect();
      window.location.reload();
    }

  }

  // reconnect wallet
  setTimeout( () => {
    if( window.client_ledger && window.client_ledger != '' ) {
      window.logged_user = true;
      setLogged(true);
      setWallet( window.client_ledger );
    }
  }, 1000);

  return (
    <div className="flex items-center">
      {!userAddress ? (
        <div className="relative">
          <table>
            <tr>
              <td valign="top">
                {!logged ? (
                <div className="relative">
                  <Btn onClick={(e) => { e.stopPropagation();  setShowDropdownNetwork(!showDropdownNetwork); setShowDropdown(false); }}>
                    <div className="flex gap-2 items-center ">
                      <img className="" src="/assets/aptos_logo.svg" />
                      <span className="relative top-0.5">APT</span>
                      <img className="px-1" src="/assets/arrow-down.svg" style={{"width": "20px"}} />
                    </div>{" "}
                  </Btn>
                      {<NetworkDropdown
                    show={showDropdownNetwork}
                    setShowDropdown={setShowDropdownNetwork}
                    selectNetwork_APT={selectNetwork_APT}
                    selectNetwork_ICP={selectNetwork_ICP}
                    selectNetwork_SOL={selectNetwork_SOL}
                    selectNetwork_ETH={selectNetwork_ETH}
                    selectNetwork_SUI={selectNetwork_SUI}
                    selectNetwork_BTC={selectNetwork_BTC}
                    ></NetworkDropdown>}
                </div>
                ) : (
                   <div className='relative'>

                     <table>
                       <tr>
                         <td className={'pr-3'} valign="top">
                           <div className="relative">
                             <Btn color="dark" onClick={(e) => { e.stopPropagation();  setShowDropdownNetwork(!showDropdownNetwork); setShowDropdown(false); }}>
                               <div className="flex gap-2 items-center ">
                                 <span className="relative top-0.5">APT</span>
                                 <img className="px-1" src="/assets/arrow-down.svg" style={{"width": "20px"}} />
                               </div>{" "}
                             </Btn>
                             {<NetworkDropdown
                                 show={showDropdownNetwork}
                                 setShowDropdown={setShowDropdownNetwork}
                                 selectNetwork_APT={selectNetwork_APT}
                                 selectNetwork_ICP={selectNetwork_ICP}
                                 selectNetwork_SOL={selectNetwork_SOL}
                                 selectNetwork_ETH={selectNetwork_ETH}
                                 selectNetwork_SUI={selectNetwork_SUI}
                                 selectNetwork_BTC={selectNetwork_BTC}
                             ></NetworkDropdown>}
                           </div>
                         </td>
                         <td valign="top">
                           <Btn id="logged_wallet_btn" color="grey" onClick={(e) => { e.stopPropagation(); disconnect(); }}>
                             <div className="flex gap-2 items-center " style={{"maxWidth" : "100px"}}>
                               <img className="" src="/assets/hero-screen/wallet.svg" alt="Connect Wallet" />
                               <span className="relative top-0.5 text-trim logged_wallet">
                                 <b className='wallet_address'>{wallet}</b>
                                 <b className="hover_logout">Log Out</b>
                               </span>
                             </div>{" "}
                           </Btn>
                         </td>
                       </tr>
                     </table>
                   </div>
               )}
              </td>

              <td valign="top" className="pl-3">
                {!logged ? (
                <Btn color="bronze" onClick={(e) => { e.stopPropagation();  setShowDropdown(!showDropdown); setShowDropdownNetwork(false); }}>
                  <div className="flex gap-2 items-center ">
                    <img className="" src="/assets/hero-screen/wallet.svg" alt="Connect Wallet" />
                    <span className="relative top-0.5">Connect</span>
                  </div>{" "}
                </Btn>
                ) : (
                    <Btn color="bronze" onClick={(e) => { e.stopPropagation(); }}>
                      <a href="https://aptos-portal.burnbattle.io/">
                        <div className="flex gap-2 items-center pb-1">
                          <span className="relative top-0.5">PORTAL</span>
                        </div>
                      </a>
                    </Btn>
                )}
                {<ConnectWalletDropdown
                    show={showDropdown}
                    setShowDropdown={setShowDropdown}
                    requestPetraConnect={requestPetraConnect}
                    requestMartianConnect={requestMartianConnect}
                ></ConnectWalletDropdown>}
              </td>
            </tr>
          </table>
        </div>
      ) : (
        <div className="text-white text-xs font-normal flex flex-col items-center justify-start">
          {header && (
            <p onClick={() => setUserAddress("")} className="pb-1">{`${userAddress.slice(
              0,
              8
            )}...${userAddress.slice(-6)}`}</p>
          )}
          {/* <CWalletbutton
            onClick={() => {
              setShowPopup(true);
            }}
            text="Mint NFT"
            spcng="px-9"
            icon={false}
            type="filled"
          /> */}
        </div>
      )}
    </div>
  );
}

export default ConnectWallet;
