import React from "react";
import Characters from "./Characters";
import styles from "../../styles/game-descr.module.scss";
export default function GameDescr() {
  return (
    <div id={"chars"} className={styles.root}>
      <div className="wrap">
        <p className="heading text-center pb-2">
          10 DIFFERENT PLAYABLE CHARCOAL WARRIOR NFT CHARACTERS TO MINT
        </p>
        <p className="paragraph text-center mb-6 max-w-[800px] mx-auto">
          When you mint your NFT charcoal warrior, you will get a random selected warrior out of 10 available options. Each warrior looks different with different animations in the game - they all have the same attacks and abilities for now - but this may be changed in the future!
        </p>
        <Characters />
      </div>
    </div>
  );
}
