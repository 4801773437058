import React, { useEffect, useState } from "react";
import TimerItem from "./TimerItem";
import { calculateTimeLeft } from "./utils";

export default function Timer() {
  const today = Date.now();
  const dayX = new Date(Date.UTC(2022, 12, 6, 15, 0, 0)).getTime();
  // const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(dayX));
  let [timeLeft, setTimeLeft] = useState({ days: 0, hours_total: 0, minutes: 0, seconds: 0 });
  let [seconds, setSeconds] = useState(0);

  window.addEventListener('season_data', function (e) {
    let _timer = window.season.timer;
    _timer.seconds = Math.floor(Math.random() * 20) + 40;
    setSeconds(_timer.seconds);
    setTimeLeft(_timer);
  });

  useEffect(() => {

    // start interval for _timer.seconds (1s)
    const timer = setInterval(( ) => {
      seconds--;
      if( seconds < 1 ) { timeLeft.minutes--; seconds = 60; }
      setSeconds( seconds );
    }, 1000);

   /* let timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(dayX));
    }, 60 * 1000);
    */
    return () => {
      clearInterval(timer);
    };
  });
  return (
    <div className=" flex items-center justify-center   relative ">
      {dayX < today ? (
        <p>{` IT'S BATTLE TIME`}</p>
      ) : (
        <>
          <div className="flex text-white items-center ">
            {/*<TimerItem time={timeLeft.days} text="Days" />*/}
            <TimerItem time={timeLeft.hours_total} text="Hours" />
            <p className="text-brown-400 font-black text-[36px] lg:text-[40px] mx-2 lg:mx-4 leading-none">
              :
            </p>
            <TimerItem time={timeLeft.minutes} text="Minutes" />
            <p className="text-brown-400 font-black text-[36px] lg:text-[40px] mx-2 lg:mx-4 leading-none">
              :
            </p>
            <TimerItem time={seconds} text="Seconds" />
          </div>
        </>
      )}
    </div>
  );
}
